import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: '',
          path: '',
          component: () => import('@/views/dashboard/pages/Investments'),
        },
        {
          name: 'Investments',
          path: 'transaction-risk/investment',
          component: () => import('@/views/dashboard/pages/Investments'),
        },
        {
          name: 'Economics',
          path: 'economic-status-risk/economics',
          component: () => import('@/views/dashboard/pages/Economics'),
        },
        {
          name: 'Environmental',
          path: 'event-risk/environmental',
          component: () => import('@/views/dashboard/pages/Environmental'),
        },
        {
          name: 'Traffic Analysis',
          path: 'event-risk/traffic-analysis',
          component: () => import('@/views/dashboard/pages/TrafficAnalysis'),
        },
        {
          name: 'Discover',
          path: 'discover',
          component: () => import('@/views/dashboard/pages/Discover'),
        },
        {
          name: 'Visualize',
          path: 'visualize',
          component: () => import('@/views/dashboard/pages/Visualize'),
        },
        {
          name: 'Map',
          path: 'Map',
          component: () => import('@/views/dashboard/pages/Map'),
        },
        {
          name: 'Graph Browser',
          path: 'tools/graph-browser',
          component: () => import('@/views/dashboard/pages/Graph'),
        },
      ],
    },
  ],
})
